<template>
  <v-dialog v-model="showDialog" max-width="600px" persistent :fullscreen="$store.state.isMobile" v-if="this.$store.getters.termsAccepted">
    <v-card>
      <v-card-title>
        Report Invite
      </v-card-title>
      <v-card-subtitle>
        <span>{{invite ? invite.accountId : 'unknown'}}</span>
      </v-card-subtitle>
      <v-card-text>
        {{`Would you like to accept the invitation from ${invite ? invite.accountId : 'unknown'} to receive their accountability reports?`}}
        <br />
        <br />
        <v-btn class="pl-0 pr-0" @click="reject(true)" text x-small :disabled="loading">{{`Block all requests from ${invite ? invite.accountId : 'unknown'}`}}</v-btn>
      </v-card-text>
      <v-card-actions>
        <v-btn @click="reject(false)" :disabled="loading">Reject</v-btn>
        <v-spacer></v-spacer>
        <v-btn color="primary" @click="accept" :loading="loading" :disabled="loading">Accept</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
  /* eslint-disable no-console */

  import StoreConstants from "../StoreConstants";
  import SegmentTracking from "@/services/SegmentTracking";
  import Utils from "@/Utils";

  let unsubscribe = null

  export default {
    name: "EmailRecipientInviteDialog",
    data() {
      return {
        loading: false,
        invite: null,
        showDialog: false
      }
    },
    beforeDestroy() {
      if(unsubscribe) {
        unsubscribe()
      }
    },
    mounted() {
      this.processInvites(this.$store.state)

      unsubscribe = this.$store.subscribe((mutation, state) => {
        if(mutation.type === StoreConstants.commits.setAccount) {
          this.processInvites(state)
        }
      })
    },
    methods: {
      processInvites(state) {
        if(this.$store.state.inApp && !this.$store.getters.isOwner) {
          this.showDialog = false
          return
        }
        // console.log('processInvites')
        // console.log(state)
        if(!state || !state.account || !state.account.account || !state.account.account.emailRecipientInvites) {
          this.showDialog = false
          return
        }
        let invites = state.account.account.emailRecipientInvites
        if(invites && invites.length > 0) {
          this.invite = invites[0]
          this.showDialog = true
        } else {
          this.showDialog = false
        }
      },
      async accept() {
        this.loading = true
        try {
          let params = {
            recipient: this.$store.state.account.accountId,
            accountId: this.invite.accountId,
            frequency: this.invite.frequency,
            version: "3"
          }

          let error
          await this.api.dailyEmailImagesAwait(params).catch(err => {
            error = err
          })

          SegmentTracking.track('ReceivedAccountabilityPartnerInvite', {
            category: 'Onboarding'
          })

          if(error) throw error

          await Utils.sleep(5000)

          this.$emit('on-accept')
        } catch(e) {
          console.log(e)
          this.$swal('An error occured, please try again.  If this repeats, please contact support@truple.io.')
        } finally {
          this.showDialog = false
          this.loading = false
          this.$store.dispatch(StoreConstants.actions.loadAccount)
        }
      },
      async reject(block) {
        if(block) {
          let result = await this.$swal({
            title: 'Block confirmation',
            text: `Are you sure you would like to block all requests from ${this.invite ? this.invite.accountId : 'unknown'}?  To undo this you'll need to email support@truple.io.`,
            showCancelButton: true
          })
          if(!result.isConfirmed) {
            return
          }
        }

        this.loading = true
        try {
          let error
          await this.api.dailyEmailUnsubscribe({
            recipient: this.$store.state.account.accountId,
            accountId: this.invite.accountId,
            frequency: this.invite.frequency,
            block: block
          }).catch(err => {
            error = err
          })

          SegmentTracking.track('ReceivedAccountabilityPartnerInvite', {
            category: 'Onboarding'
          })

          if(error) throw error
        } catch(e) {
          console.log(e)
          this.$swal('An error occured, please try again.  If this repeats, please contact support@truple.io.')
        } finally {
          this.showDialog = false
          this.loading = false
          this.$store.dispatch(StoreConstants.actions.loadAccount)
        }
      }
    }
  }
</script>

<style scoped>

</style>
