var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return this.$store.getters.termsAccepted
    ? _c(
        "v-dialog",
        {
          attrs: {
            "max-width": "600px",
            persistent: "",
            fullscreen: _vm.$store.state.isMobile
          },
          model: {
            value: _vm.showDialog,
            callback: function($$v) {
              _vm.showDialog = $$v
            },
            expression: "showDialog"
          }
        },
        [
          _c(
            "v-card",
            [
              _c("v-card-title", [_vm._v("\n      Report Invite\n    ")]),
              _c("v-card-subtitle", [
                _c("span", [
                  _vm._v(_vm._s(_vm.invite ? _vm.invite.accountId : "unknown"))
                ])
              ]),
              _c(
                "v-card-text",
                [
                  _vm._v(
                    "\n      " +
                      _vm._s(
                        "Would you like to accept the invitation from " +
                          (_vm.invite ? _vm.invite.accountId : "unknown") +
                          " to receive their accountability reports?"
                      ) +
                      "\n      "
                  ),
                  _c("br"),
                  _c("br"),
                  _c(
                    "v-btn",
                    {
                      staticClass: "pl-0 pr-0",
                      attrs: { text: "", "x-small": "", disabled: _vm.loading },
                      on: {
                        click: function($event) {
                          return _vm.reject(true)
                        }
                      }
                    },
                    [
                      _vm._v(
                        _vm._s(
                          "Block all requests from " +
                            (_vm.invite ? _vm.invite.accountId : "unknown")
                        )
                      )
                    ]
                  )
                ],
                1
              ),
              _c(
                "v-card-actions",
                [
                  _c(
                    "v-btn",
                    {
                      attrs: { disabled: _vm.loading },
                      on: {
                        click: function($event) {
                          return _vm.reject(false)
                        }
                      }
                    },
                    [_vm._v("Reject")]
                  ),
                  _c("v-spacer"),
                  _c(
                    "v-btn",
                    {
                      attrs: {
                        color: "primary",
                        loading: _vm.loading,
                        disabled: _vm.loading
                      },
                      on: { click: _vm.accept }
                    },
                    [_vm._v("Accept")]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }