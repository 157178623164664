<template>
  <v-text-field
      outlined
      :disabled="disabled"
      v-model="internalValue"
      :persistent-hint="true"
      :type="showPassword ? 'text' : 'password'"
      :placeholder="placeholder"
      :autocomplete="autocomplete"
      :hint="hint"
      :append-icon="showPassword ? 'visibility_off' : 'visibility'"
      @click:append="togglePasswordVisibility"
  >
  </v-text-field>
</template>

<script>
export default {
  name: 'E2EEPasscodeInput',
  props: {
    disabled: {
      type: Boolean,
      default: false
    },
    placeholder: {
      type: String,
      default: 'Enter your passcode'
    },
    hint: {
      type: String,
      default: 'This is your password hint.'
    },
    value: {
      type: String,
      default: ''
    },
    autocomplete: {
      type: String,
      default: 'current-password'
    }
  },
  data() {
    return {
      showPassword: false,
      internalValue: this.value
    };
  },
  methods: {
    togglePasswordVisibility() {
      this.showPassword = !this.showPassword;
    }
  },
  watch: {
    internalValue(val) {
      this.$emit('input', val);
    },
    value(val) {
      this.internalValue = val;
    }
  }
};
</script>
